module.exports = {
  // App Info
  // TODO: Fix apple link
  appstore_link:
    'https://play.google.com/store/apps/dev?id=5556017317065936739', // Enter App Store URL.
  appstore_consulta_cpf:
    'https://play.google.com/store/apps/details?id=com.appcraftlabs.consultacpf',
  appstore_unseen:
    'https://play.google.com/store/apps/details?id=com.diegowendel.showme',
  appstore_status_saver:
    'https://play.google.com/store/apps/details?id=com.appcraftlabs.statussaver',
  appstore_salario_liquido:
    'https://play.google.com/store/apps/details?id=com.calculadorasalario',
  playstore_link:
    'https://play.google.com/store/apps/dev?id=5556017317065936739', // Enter Google Play Store URL.
  video_or_screenshot: 'screenshot', // "screenshot" or "video"
  app_author: 'App Craft Labs',
  app_url: 'https://appcraftlabs.com', // Domain of your website without path_prefix.
  path_prefix: '/', // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name: 'App Craft Labs',
  app_price: 'Technology to Empower People',
  app_description: 'Desenvolvimento de Apps para Android e iOS',
  app_keywords: ['automatic', 'gatsbyjs', 'app', 'landing page'],

  // Company Info
  email_address: 'support@appcraftlabs.com',

  // Theme
  header_background: 'rgba(0, 0, 0, 0.1)',
  topbar_title_color: '#ffffff',
  cover_overlay_color_rgba: 'rgba(54, 59, 61, 0.8)',
  device_color: 'black', // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color: 'ffffff',
  primary_text_color: '#000',
  content_width: '1170px',
  font: `"Helvetica Neue", sans-serif`,
  link_color: '#1d63ea',
  app_title_color: '#ffffff',
  app_price_color: '#ffffff',
  app_description_color: '#ffffff',
  feature_title_color: '#000000',
  feature_text_color: '#666666',
  feature_icons_foreground_color: '#1d63ea',
  feature_icons_background_color: '#e6e6e6',
  social_icons_foreground_color: '#666666',
  social_icons_background_color: '#e6e6e6',
  footer_text_color: '#666666',
};
